define("ember-cli-spinner/index", ["exports", "ember"], function (exports, _ember) {

  var Spinner = _ember["default"].Service.extend({
    spinners: {},
    add: function add(spinner) {
      var spinners = this.get("spinners");
      spinners[spinner.id] = spinner;
    },
    remove: function remove(id) {
      var spinners = this.get("spinners");
      spinners[id].destroy();
      spinners[id] = undefined;
    },
    show: function show(id, options) {
      var spinners = this.get("spinners");
      spinners[id].show(options);
    },
    hide: function hide(id) {
      var spinners = this.get("spinners");
      spinners[id].hide();
    },
    setAnimation: function setAnimation(id, type) {
      var spinners = this.get("spinners");
      spinners[id].setAnimation(type);
    },
    setBgColor: function setBgColor(id, color) {
      var spinners = this.get("spinners");
      spinners[id].setBgColor(color);
    },
    setHeight: function setHeight(id, height) {
      var spinners = this.get("spinners");
      spinners[id].setHeight(height);
    },
    setWidth: function setWidth(id, width) {
      var spinners = this.get("spinners");
      spinners[id].setHeight(width);
    }
  });

  exports["default"] = Spinner;
});
define("lodash/_realNames", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /** Used to lookup unminified function names. */
  var realNames = {};

  exports.default = realNames;
});
define('lodash/partition', ['exports', 'lodash/_createAggregator'], function (exports, _createAggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Creates an array of elements split into two groups, the first of which
   * contains elements `predicate` returns truthy for, the second of which
   * contains elements `predicate` returns falsey for. The predicate is
   * invoked with one argument: (value).
   *
   * @static
   * @memberOf _
   * @since 3.0.0
   * @category Collection
   * @param {Array|Object} collection The collection to iterate over.
   * @param {Function} [predicate=_.identity] The function invoked per iteration.
   * @returns {Array} Returns the array of grouped elements.
   * @example
   *
   * var users = [
   *   { 'user': 'barney',  'age': 36, 'active': false },
   *   { 'user': 'fred',    'age': 40, 'active': true },
   *   { 'user': 'pebbles', 'age': 1,  'active': false }
   * ];
   *
   * _.partition(users, function(o) { return o.active; });
   * // => objects for [['fred'], ['barney', 'pebbles']]
   *
   * // The `_.matches` iteratee shorthand.
   * _.partition(users, { 'age': 1, 'active': false });
   * // => objects for [['pebbles'], ['barney', 'fred']]
   *
   * // The `_.matchesProperty` iteratee shorthand.
   * _.partition(users, ['active', false]);
   * // => objects for [['barney', 'pebbles'], ['fred']]
   *
   * // The `_.property` iteratee shorthand.
   * _.partition(users, 'active');
   * // => objects for [['fred'], ['barney', 'pebbles']]
   */
  var partition = (0, _createAggregator.default)(function (result, value, key) {
    result[key ? 0 : 1].push(value);
  }, function () {
    return [[], []];
  });

  exports.default = partition;
});
define('ember-cli-mirage/ember-data', ['exports', 'require', 'ember-get-config', 'ember-cli-mirage/assert', 'ember-cli-mirage/utils/ember-data', 'ember-cli-mirage'], function (exports, _require, _emberGetConfig, _emberCliMirageAssert, _emberCliMirageUtilsEmberData, _emberCliMirage) {
  exports.getDsModels = getDsModels;
  exports.getModels = getModels;
  exports.modelFor = modelFor;
  var modulePrefix = _emberGetConfig['default'].modulePrefix;
  var podModulePrefix = _emberGetConfig['default'].podModulePrefix;

  // Caches
  var DsModels = undefined,
      Models = undefined;

  /**
   * Get all ember data models under the app's namespaces
   *
   * @method getDsModels
   * @private
   * @return {Object} models
   */

  function getDsModels() {
    if (DsModels) {
      return DsModels;
    }

    var moduleMap = requirejs.entries;
    var modelMatchRegex = new RegExp('^(' + modulePrefix + '/models|' + podModulePrefix + '/.*/model$)', 'i');

    DsModels = {};

    if (!_emberCliMirageUtilsEmberData.hasEmberData) {
      return DsModels;
    }

    Object.keys(moduleMap).filter(function (module) {
      return !!module.match(modelMatchRegex);
    }).forEach(function (path) {
      var paths = path.split('/');
      var modelName = paths[paths.length - 1];
      var model = (0, _require['default'])(path, null, null, true)['default'];

      if (modelName === 'model') {
        modelName = paths[paths.length - 2];
      }

      if ((0, _emberCliMirageUtilsEmberData.isDsModel)(model)) {
        DsModels[modelName] = model;
      }
    });

    return DsModels;
  }

  /**
   * Get all mirage models for each of the ember-data models
   *
   * @method getModels
   * @private
   * @return {Object} models
   */

  function getModels() {
    if (Models) {
      return Models;
    }

    var models = getDsModels();
    Models = {};

    Object.keys(models).forEach(function (modelName) {
      var model = models[modelName];
      var attrs = {};

      model.eachRelationship(function (name, r) {
        if (r.kind === 'belongsTo') {
          attrs[name] = (0, _emberCliMirage.belongsTo)(r.type, r.options);
        } else if (r.kind === 'hasMany') {
          attrs[name] = (0, _emberCliMirage.hasMany)(r.type, r.options);
        }
      });

      Models[modelName] = _emberCliMirage.Model.extend(attrs);
    });

    return Models;
  }

  /**
   * A lookup method for an autogenerated model
   *
   * @method modelFor
   * @private
   * @param  {String} name
   * @return {Model}
   */

  function modelFor(name) {
    var models = getModels();
    (0, _emberCliMirageAssert['default'])(!!models[name], 'Model of type \'' + name + '\' does not exist.');
    return models[name];
  }
});
/* global requirejs */
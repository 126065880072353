define('ember-cli-input-mask/components/input-mask', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.__esModule = true;

  var _ember2 = _interopRequireDefault(_ember);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = _ember2.default.TextField.extend({
    initializeMask: function () {
      var mask = this.get('mask');

      this.$().inputmask(mask, {
        onBeforeMask: function (value) {
          if (mask === 'mm/dd/yyyy') {
            return moment(new Date(value)).format('L');
          }
        }
      });

      // The input mask changes the value of the input from the original to a
      // formatted version. We need to manually send that change back to the
      // controller.
      this.set('value', this.$().val());
    }.on('didInsertElement'),

    removeMask: function () {
      this.$().inputmask('remove');
    }.on('willDestroyElement')
  });
});
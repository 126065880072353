define('ember-cli-spinner/components/ember-cli-spinner', ['exports', 'ember', 'ember-cli-spinner/templates/components/ember-cli-spinner'], function (exports, _ember, _emberCliSpinnerTemplatesComponentsEmberCliSpinner) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  var run = _ember['default'].run;
  var escapeExpression = _ember['default'].Handlebars.Utils.escapeExpression;
  exports['default'] = Component.extend({
    layout: _emberCliSpinnerTemplatesComponentsEmberCliSpinner['default'],
    timeout: undefined,
    isShow: false,
    type: "pulse", //default
    counters: [],
    className: "sk-spinner-pulse",
    bgColor: "rgba(0, 0, 0, 0.5)",
    height: "40px",
    width: "40px",
    bgColorStyle: _ember['default'].computed('bgColor', function () {
      return _ember['default'].String.htmlSafe(escapeExpression('background:' + this.get('bgColor') + ';'));
    }),
    sizeStyle: _ember['default'].computed('height', 'width', function () {
      return _ember['default'].String.htmlSafe(escapeExpression('height:' + this.get('height') + ';width:' + this.get('width') + ';'));
    }),
    spinner: inject.service(),
    color: "white",
    didInsertElement: function didInsertElement() {
      var spinner = this.get("spinner");
      var type = this.get("type");
      this.setAnimation(type); //Set Spinner Type
      spinner.add(this);
    },
    setColor: function setColor(color) {
      this.set("color", color);
    },
    setBgColor: function setBgColor(color) {
      this.set("bgColor", color);
    },
    setHeight: function setHeight(height) {
      this.set("height", height);
    },
    setWidth: function setWidth(width) {
      this.set("width", width);
    },
    setAnimation: function setAnimation(type) {
      switch (type) {
        case "circles":
          this.set("className", "sk-circle");
          this.set("counters", [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
          break;
        case "pulse":
          this.set("className", "sk-spinner-pulse");
          this.set("counters", []);
          break;
        case "fading-circle":
          this.set("className", "sk-fading-circle");
          this.set("counters", [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
          break;
        case "wave":
          this.set("className", "sk-wave");
          this.set("counters", [1, 2, 3, 4, 5]);
          break;
        case "rotating-plane":
          this.set("className", "sk-rotating-plane");
          this.set("counters", []);
          break;
        case "folding-cube":
          this.set("className", "sk-folding-cube");
          this.set("counters", [1, 2, 3, 4]);
          break;
        case "double-bounce":
          this.set("className", "sk-double-bounce");
          this.set("counters", [1, 2]);
          break;
        case "wandering-cubes":
          this.set("className", "sk-wandering-cubes");
          this.set("counters", [1, 2]);
          break;
        case "chasing-dots":
          this.set("className", "sk-chasing-dots");
          this.set("counters", [1, 2]);
          break;
        case "three-bounce":
          this.set("className", "sk-three-bounce");
          this.set("counters", [1, 2, 3]);
          break;
        case "three-bounce-horizontal":
          this.set("className", "sk-three-bounce-horizontal");
          this.set("counters", [1, 2, 3]);
          break;
        case "cube-grid":
          this.set("className", "sk-cube-grid");
          this.set("counters", [1, 2, 3, 4, 5, 6, 7, 8, 9]);
          break;
        default:
          this.set("className", "sk-spinner-pulse");
          this.set("counters", []);
          break;
      }
    },
    show: function show(options) {
      var opts = options || {};
      var timeout = opts.timeout;
      if (timeout !== undefined) {
        this.set("isShow", true);
        run.later(this, function () {
          this.set("isShow", false);
        }, timeout);
      } else {
        this.set("isShow", true);
      }
    },
    hide: function hide() {
      this.set("isShow", false);
    }
  });
});
define('ember-cli-carousel/components/ember-cli-carousel-item', ['exports', 'ember', 'ember-cli-carousel/templates/components/ember-cli-carousel-item'], function (exports, _ember, _emberCliCarouselTemplatesComponentsEmberCliCarouselItem) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliCarouselTemplatesComponentsEmberCliCarouselItem['default'],
    tagName: 'div',
    classNames: ['ember-cli-carousel-item'],
    classNameBindings: ['showForward:forward', 'forwardHide:forward-hide', 'reverseHide:reverse-hide', 'showReverse:reverse'],
    index: 0,
    parentContainer: null,
    showForward: _ember['default'].computed('flag', function () {
      return this.get('flag') == 1;
    }),
    forwardHide: _ember['default'].computed('flag', function () {
      return this.get('flag') == 2;
    }),
    reverseHide: _ember['default'].computed('flag', function () {
      return this.get('flag') == -2;
    }),
    showReverse: _ember['default'].computed('flag', function () {
      return this.get('flag') == -1;
    }),
    isActive: _ember['default'].computed('index', 'parentContainer.activeIndex', function () {
      if (this.get('index') == this.get('parentContainer.activeIndex')) return true;
      return false;
    }),
    init: function init() {
      this.set('parentContainer', this.nearestWithProperty('carousel_items'));
      this.get('parentContainer.carousel_items').pushObject(this);
      this.set('index', this.get('parentContainer.carousel_items.length') - 1);
      if (this.get('index') == 0) this.set('flag', this.get('parentContainer.direction') * 1);
      this._super();
    }
  });
});
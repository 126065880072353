define('lodash/_Uint8Array', ['exports', 'lodash/_root'], function (exports, _root) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /** Built-in value references. */
  var Uint8Array = _root.default.Uint8Array;

  exports.default = Uint8Array;
});
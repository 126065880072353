define('ember-cli-carousel/components/ember-cli-carousel', ['exports', 'ember', 'ember-cli-carousel/templates/components/ember-cli-carousel'], function (exports, _ember, _emberCliCarouselTemplatesComponentsEmberCliCarousel) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliCarouselTemplatesComponentsEmberCliCarousel['default'],
    tagName: 'div',
    reverse: false,
    direction: _ember['default'].computed('reverse', function () {
      if (this.get('reverse') == true) return -1;
      return 1;
    }),
    classNames: ['ember-cli-carousel'],
    activeIndex: 0,
    runLoop: null,
    carouselLength: _ember['default'].computed('carousel_items', function () {
      return this.get('carousel_items.length');
    }),
    timeInterval: 2000,
    init: function init() {
      this.set('carousel_items', _ember['default'].A());
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('runLoop', _ember['default'].run.later(this, this.slideRight, this.get('timeInterval')));
    },
    slideRight: function slideRight() {
      this.get('carousel_items').objectAt(this.get('activeIndex')).set('flag', this.get('direction') * 2);
      this.set('activeIndex', (this.get('activeIndex') + 1) % this.get('carouselLength'));
      this.get('carousel_items').objectAt(this.get('activeIndex')).set('flag', this.get('direction') * 1);
      this.set('runLoop', _ember['default'].run.later(this, this.slideRight, this.get('timeInterval')));
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].run.cancel(this.get('runLoop'));
    },
    actions: {
      slideTo: function slideTo(index) {
        _ember['default'].run.cancel(this.get('runLoop'));
        var dir = index < this.get('activeIndex') ? -1 : 1;
        if (index != this.get('activeIndex')) {
          this.get('carousel_items').objectAt(this.get('activeIndex')).set('flag', dir * this.get('direction') * 2);
          this.set('activeIndex', index);
          this.get('carousel_items').objectAt(this.get('activeIndex')).set('flag', dir * this.get('direction') * 1);
        }
        this.set('runLoop', _ember['default'].run.later(this, this.slideRight, this.get('timeInterval')));
      }
    }
  });
});